<template>
  <CModal
    :show="show"
    :closeOnBackdrop="false"
    :centered="true"
    size="lg"
    id="editInvoice"
  >
    <template #header>
      <h5 class="modal-title">{{ title }}</h5>
    </template>

    <div v-if="invoice !== null">
      <p>
        Rimuovi la selezione ai prodotti che non vuoi inserire in questa
        fattura. I prodotti rimossi verranno inseriti nella prossima.
      </p>

      <CAlert v-if="error.hasGeneralErrors()" color="danger" class="mb-3">
        {{ error.general().join(" ") }}
      </CAlert>

      <div class="ui-type-subheading text-uppercase text-gray-600">
        Destinario
      </div>
      <h4>{{ invoiceDestinatary() }}</h4>
      <br />

      <form action="" @submit.prevent="onSubmit" ref="form">
        <CRow>
          <CCol md="12">
            <CInput v-model="search" placeholder="Cerca prodotti">
              <template #prepend-content>
                <font-awesome-icon icon="search" />
              </template>
            </CInput>
          </CCol>
        </CRow>

        <div class="d-flex justify-content-center m-3" v-if="loadingDetails">
          <CSpinner color="info" />
        </div>

        <div class="table-responsive details-table-wrapper" v-else>
          <table class="table">
            <thead>
              <tr>
                <th></th>
                <th>NOME</th>
                <th>QUANTITÀ</th>
                <th>PAGATO</th>
                <th>IVA</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <fragment v-for="group in groups" :key="group.id">
                <tr class="highlighted">
                  <td width="40">
                    <CInputCheckbox
                      inline
                      custom
                      :checked="checkSelected(group)"
                      :disabled="isGroupSelectordDisabled(group)"
                      @update:checked="
                        (checked) => onCheckboxGroupChange(checked, group)
                      "
                    />
                  </td>
                  <td colspan="5">
                    <strong>{{ group.label }}</strong>
                  </td>
                </tr>
                <tr
                  v-for="detail in searchResults(group.details)"
                  :key="detail.id"
                >
                  <td width="40">
                    <CInputCheckbox
                      inline
                      custom
                      :checked.sync="detail.select"
                      :disabled="detail.disabled"
                      @update:checked="
                        (checked) => onCheckboxChange(checked, detail)
                      "
                    />
                  </td>
                  <td>
                    <strong>{{ productName(detail) }}</strong>
                    <div>{{ variantName(detail) }}</div>
                  </td>
                  <td>{{ quantity(detail) }}</td>
                  <td>
                    <strong>{{ price(detail) }}</strong>
                  </td>
                  <td>{{ taxRate(detail) }}%</td>
                  <td></td>
                </tr>
              </fragment>
            </tbody>
          </table>
        </div>
      </form>
    </div>

    <template #footer>
      <CRow class="justify-content-center align-items-end">
        <CCol md="3">
          <CInput
            v-model="discount"
            label="Sconto"
            class="mb-0"
            :append="currencySymbol"
            type="number"
            min="0"
            step="0.01"
          />
        </CCol>
        <CCol md="9">
          <CRow class="justify-content-end align-items-end">
            <CCol class="text-right"
              ><div class="ui-type-subheading text-uppercase text-gray-600">
                Totale Importo
              </div>
              <h4 class="mb-0">
                {{ !loadingDetails ? invoiceAmount() : "--" }}
              </h4>
            </CCol>

            <CCol class="text-right">
              <CButton
                color="primary"
                variant="outline"
                @click.prevent="close"
                class="mr-2"
                >ANNULLA</CButton
              >
              <vue-ladda
                :loading="loading"
                :disabled="loadingDetails || loading"
                data-style="zoom-in"
                button-class="btn btn-primary px-4"
                @click.prevent="onSubmit"
                >CONFERMA</vue-ladda
              >
            </CCol>
          </CRow>
        </CCol>
      </CRow>
    </template>
  </CModal>
</template>

<script>
import get from "lodash/get";
import cloneDeep from "lodash/cloneDeep";
import EventBus from "../../../../helpers/EventBus";
import {
  formatDate,
  formatPrice,
  getOptionLabel,
} from "../../../../helpers/common";
import errorResponse from "../../../../helpers/error";
import { mapGetters } from "vuex";
import { ApiCall, GetDataManagerNew } from "../../../../ds";
import { Query } from "@syncfusion/ej2-data";
import { INVOICE_TYPE } from "../../../../config/global";
import { getPriceUnitOptions } from "../../../../helpers/options";

export default {
  name: "InvoicesEditModal",

  data() {
    return {
      loadingDetails: false,
      loading: false,
      error: errorResponse(),
      search: "",
      discount: 0,
      groups: [],
    };
  },

  watch: {
    show(value) {
      // reset on open/close modal
      if (value) {
        this.reset();
        this.loadDetails();
      }
    },
  },

  computed: {
    title() {
      return "Modifica fattura";
    },

    show() {
      return this.$store.state.invoices.edit.show;
    },

    invoice() {
      return this.$store.state.invoices.edit.invoice;
    },

    lang() {
      return this.$i18n.locale;
    },

    ...mapGetters("connections", ["currencySymbol"]),
  },

  methods: {
    reset() {
      this.loadingDetails = false;
      this.loading = false;
      this.error.reset();
      this.search = "";
      this.discount = get(
        this,
        "$store.state.invoices.edit.invoice.discount",
        0
      );
      this.invoiceAmountOrig =
        get(this.invoice, "price") + get(this.invoice, "discount", 0);
      this.groups = [];
    },

    makeGroups(rows, invoice_type) {
      const groups = rows.reduce((groups, row) => {
        let groupKey = row.id;
        let grouplabel = "";

        switch (invoice_type) {
          case INVOICE_TYPE.CUSTOMER:
            groupKey = get(row, "orderdetail.order.id").toString();
            grouplabel = `ORDINE #${groupKey}`;
            break;
          case INVOICE_TYPE.PRODUCER:
            groupKey = get(row, "orderdetail.ddmp.delivery_date").toISOString();
            grouplabel = `CHIUSURA ORDINE #${formatDate(
              new Date(groupKey),
              "dd-MM-yyyy · HH:mm"
            )}`;
            break;
          case INVOICE_TYPE.ISLAND:
          case INVOICE_TYPE.MARKET:
            groupKey = get(
              row,
              "orderdetail.order.ddmi.delivery_date"
            ).toISOString();
            grouplabel = `CHIUSURA ORDINE #${formatDate(
              new Date(groupKey),
              "dd-MM-yyyy · HH:mm"
            )}`;
            break;
        }

        const group = groups.find((g) => g.id === groupKey);
        const detail = { ...row, disabled: false };

        if (!group) {
          groups.push({
            id: groupKey,
            label: grouplabel,
            details: [detail],
          });
        } else {
          group.details.push(detail);
        }
        return groups;
      }, []);

      return groups;
    },

    loadDetails() {
      this.loadingDetails = true;
      const roleId = this.$store.state.role.id;
      const invoiceId = this.invoice.id;

      ApiCall(
        GetDataManagerNew("role_invoices_toemit_details", [roleId, invoiceId]),
        new Query(),
        (response) => {
          this.groups = this.makeGroups(response.result, this.invoice.i_type);
          this.loadingDetails = false;
        },
        (error) => {
          console.error(error);
          this.loadingDetails = false;
        }
      );
    },

    close() {
      this.$store.commit("invoices/closeEditModal");
    },

    invoiceDestinatary() {
      return this.invoice.i_to.name;
    },

    invoiceAmount() {
      let amount = 0;
      if (this.groups.length > 0) {
        const totalAmount = this.groups.reduce((acc, group) => {
          const groupTotalAmount = group.details
            .filter((d) => d.select)
            .reduce((a, d) => a + d.price, 0);
          return acc + groupTotalAmount;
        }, 0);

        amount = totalAmount - this.discount;
      }
      return formatPrice(amount, this.currencySymbol).format();
    },

    productName(detail) {
      return get(
        detail,
        `orderdetail.ppm.lot.productproducer.translations.${this.lang}.name`
      );
    },

    variantName(detail) {
      return get(detail, `orderdetail.ppm.lot.translations.${this.lang}.name`);
    },

    quantity(detail) {
      return `${get(detail, `orderdetail.qta`)}${
        this.isWeightProduct(detail) ? ` ${this.pwUnit(detail)}` : ""
      }`;
    },

    pwUnit(detail) {
      return getOptionLabel(
        get(detail, `orderdetail.ppm.lot.productproducer.pw_unit`),
        getPriceUnitOptions()
      );
    },

    isWeightProduct(detail) {
      return get(detail, "orderdetail.ppm.lot.productproducer.pw", false);
    },

    taxRate(detail) {
      return get(detail, `orderdetail.ppm.lot.tax_rate.rate`);
    },

    price(detail) {
      return formatPrice(detail.price, this.currencySymbol).format();
    },

    checkSelected(group) {
      return (
        group.details.length ===
        group.details.filter((item) => item.select === true).length
      );
    },

    isGroupSelectordDisabled(group) {
      return group.details.filter((item) => item.disabled === true).length > 0;
    },

    onCheckboxGroupChange(checked, group) {
      const groupsClone = cloneDeep(this.groups);
      const g = this.groups.find((g) => g.id === group.id);

      const ids = [];
      if (g) {
        g.details.forEach((product) => {
          product.disabled = true;
          ids.push(product.id);
        });

        const roleId = this.$store.state.role.id;
        const invoiceId = this.invoice.id;
        this.error.reset();

        this.$store
          .dispatch("invoices/setInvoiceDetailSelected", {
            roleId,
            invoiceId,
            ids: ids,
            select: checked,
          })
          .then(() => {
            g.details.forEach((product) => {
              product.select = checked;
              product.disabled = false;
            });
          })
          .catch((error) => {
            this.error.set(error.response.data.errors);
            this.groups = groupsClone;
          });
      }
    },

    onCheckboxChange(checked, detail) {
      detail.disabled = true;
      const roleId = this.$store.state.role.id;
      const invoiceId = this.invoice.id;
      this.error.reset();
      this.$store
        .dispatch("invoices/setInvoiceDetailSelected", {
          roleId,
          invoiceId,
          ids: [detail.id],
          select: checked,
        })
        .then(() => {
          detail.selected = checked;
        })
        .catch((error) => {
          detail.selected = !checked;
          this.error.set(error.response.data.errors);
        })
        .finally(() => {
          detail.disabled = false;
        });
    },

    searchResults(rows) {
      let filteredRows = rows;
      if (this.search !== "" && this.search) {
        filteredRows = filteredRows.filter((item) => {
          return (
            item.productName
              .toLowerCase()
              .includes(this.search.toLowerCase()) ||
            item.variantName.toLowerCase().includes(this.search.toLowerCase())
          );
        });
      }
      return filteredRows;
    },

    onSubmit() {
      const roleId = this.$store.state.role.id;
      const invoiceId = this.invoice.id;
      const discount = this.discount;

      this.error.reset();
      this.loading = true;
      this.$store
        .dispatch("invoices/editInvoice", { roleId, invoiceId, discount })
        .then(() => {
          this.close();
          EventBus.$emit("invoices:refresh");
        })
        .catch((error) => {
          this.error.set(error.response.data.errors);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped>
#editInvoice >>> .modal-footer {
  display: block;
}
.details-table-wrapper {
  max-height: 350px;
  overflow-y: scroll;
}
</style>
